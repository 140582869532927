import { createApp } from 'vue'
import axios from 'axios';
import App from './App.vue'
import './assets/styles.css'
import router from './router'
import store from './store';

import vue3GoogleLogin from 'vue3-google-login'

import vuetify from "./plugins/vuetify";

// アプリケーションの初期化
const app = createApp(App);

// トークンリフレッシュ用のエンドポイント
const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
const TOKEN_REFRESH_ENDPOINT = `${apiEndpoint}/refresh-token/`; //'http://127.0.0.1:8000/api/v1/refresh-token/'

// Axios インターセプタの設定
/*axios.interceptors.response.use(
  response => response, // 正常レスポンスの場合はそのまま返す
  async error => {
    const originalRequest = error.config;

    // リフレッシュトークンのリクエストが失敗した場合、またはリフレッシュトークン自体が期限切れの場合
    if (originalRequest.url === TOKEN_REFRESH_ENDPOINT) {
      store.commit('setAuthentication', false);
      store.commit('setTokenExpired', true);
      return Promise.reject(error);
    }

    // 401または403エラーで、かつリフレッシュトークンのリクエストでない場合にトークンリフレッシュを試みる
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // トークンリフレッシュのアクションを呼び出す
        await store.dispatch('refreshToken');
        // リフレッシュ成功後、元のリクエストを再送信
        return axios(originalRequest);
      } catch (refreshError) {
        // トークンリフレッシュ失敗時の処理
        store.commit('setAuthentication', false);
        store.commit('setTokenExpired', true);
        return Promise.reject(refreshError);
      }
    }

    // その他のエラーはそのまま返す
    return Promise.reject(error);
  }
);*/

axios.interceptors.response.use(
  response => response, // 正常レスポンスの場合はそのまま返す
  async error => {
    const originalRequest = error.config;

    if (originalRequest.url === TOKEN_REFRESH_ENDPOINT) {
      store.commit('setAuthentication', false);
      store.commit('setTokenExpired', true);
      return Promise.reject(error);
    }

    // 401または403エラーで、かつリフレッシュトークンのリクエストでない場合にトークンリフレッシュを試みる
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // トークンリフレッシュのアクションを呼び出し、新しいトークンを取得
        const newToken = await store.dispatch('refreshToken');
        // 新しいトークンでリクエストのAuthorizationヘッダーを更新
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        // リフレッシュ成功後、元のリクエストを再送信
        return axios(originalRequest);
      } catch (refreshError) {
        // トークンリフレッシュ失敗時の処理
        store.commit('setAuthentication', false);
        store.commit('setTokenExpired', true);
        return Promise.reject(refreshError);
      }
    }

    // その他のエラーはそのまま返す
    return Promise.reject(error);
  }
);



//createApp(App)
app
  .use(router)
  .use(store)
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,// クライアントID
    scope: 'email profile openid https://www.googleapis.com/auth/youtube.readonly'
  })
  .use(vuetify)
  .mount('#app')


