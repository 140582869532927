<template>
<div class="bg-white shadow-md rounded-lg p-6 mx-auto mt-6 max-w-7xl">

    <!-- エラーメッセージ -->
    <div v-if="errorMessage" class="flex items-center justify-center px-4 py-3 space-x-3 bg-red-100 border border-red-400 rounded text-red-700" role="alert">
        <img :src="alertIcon" alt="alert icon" class="w-6 h-6">
        <span class="block sm:inline">{{ errorMessage }}</span>
        <span class=" top-0 bottom-0 right-0 px-4 py-3" @click="clearErrorMessage">
            <svg class="w-6 h-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 14.849a1 1 0 0 1-1.414 0L10 11.414 7.056 14.349a1 1 0 1 1-1.414-1.414L8.586 10 5.642 7.056a1 1 0 0 1 1.414-1.414L10 8.586l2.944-2.944a1 1 0 0 1 1.414 1.414L11.414 10l2.934 2.935a1 1 0 0 1 0 1.414z" />
            </svg>
        </span>
    </div>
    <h1 class="text-xl font-bold mb-4">記事公開設定</h1>
    <div class="grid lg:grid-cols-3 gap-4">

        <div class="lg:col-span-2">
            <div>
                <label class="block mb-2 font-semibold text-base">公開日時 <span class="text-red-500 text-sm">必須</span></label>
                <div class="mb-4">
                    <input type="radio" id="publishNow" value="now" v-model="publishOption" class="mr-2">
                    <label for="publishNow">すぐに公開する</label>
                </div>
                <div class="mb-4">
                    <input type="radio" id="schedulePublish" value="schedule" v-model="publishOption" class="mr-2">
                    <label for="schedulePublish">予約投稿する</label>
                    <!--<input type="datetime-local" v-if="publishOption === 'schedule'" v-model="scheduledTime" class="ml-4 p-2 border rounded">-->
                    <!-- 予約投稿の日時入力フィールド -->
                    <div v-if="publishOption === 'schedule'" class="mb-4">
                        <input type="datetime-local" v-model="scheduledTime" class="ml-4 p-2 border rounded">
                        <div v-if="isScheduledTimeInvalid" class="text-red-500">過去の日付、時間は設定できません。</div>
                    </div>
                </div>
                <div v-if="!publishOption && validationAttempted" class="text-red-500">必須項目です。選択してください。</div>
            </div>

            <div class="mb-4">

                <div class="flex items-center mb-2">
                    <label class="block mr-2 font-semibold text-base">記事タイトル <span class="text-red-500 text-sm">必須</span></label>
                    <!-- ツールチップトリガー -->
                    <div class="relative group" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                        <!-- SVG アイコン -->
                        <img :src="tooltipIcn" alt="info" class="w-4 h-4">
                        <!-- ツールチップコンテンツ -->
                        <div v-if="showTooltip" class="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-lg p-2 -mt-1 ml-5" style="width: 200px; z-index: 50;">
                            絵文字、旧漢字、HTMLタグは使用できません。
                        </div>
                    </div>
                </div>
                <div v-for="(title, key) in articleTitles" :key="key" class="mb-2">
                    <input type="radio" :id="key" :value="title" v-model="selectedTitle" class="mr-2">
                    <label :for="key">{{ title }}</label>
                </div>
                <div>
                    <input type="radio" id="customTitle" value="custom" v-model="selectedTitle" class="mr-2">

                    <label for="customTitle">自分で作成する</label>

                    <!--<input type="text" v-if="selectedTitle === 'custom'" v-model="customTitle" :readonly="selectedTitle !== 'custom'" placeholder="タイトルを記述してください。" class="w-full p-2 border rounded">-->
                    <input type="text" v-model="customTitle" :readonly="selectedTitle !== 'custom'" placeholder="タイトルを記述してください。" class="w-full p-2 border rounded">

                </div>
                <div v-if="!selectedTitle && validationAttempted || (selectedTitle === 'custom' && !customTitle && validationAttempted) " class="text-red-500">記事タイトルは必須です。また旧漢字やHTMLタグ、絵文字は使用できません。</div>
            </div>

            <div class="mb-4">
                <label class="block mb-2 font-semibold text-base">記事カテゴリ <span class="text-red-500 text-sm">必須</span></label>
                <select v-model="selectedCategory" class="p-2 border rounded">
                    <option disabled value="">選択してください</option>
                    <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
                </select>
                <div v-if="!selectedCategory && validationAttempted" class="text-red-500">必須項目です。選択してください。</div>
            </div>

            <div class="mb-4">
                <!-- 記事本文とツールチップアイコンを横に並べる -->
                <div class="flex items-center mb-2">
                    <label class="block mr-2 font-semibold text-base">記事本文 <span class="text-red-500 text-sm">必須</span></label>
                    <!-- ツールチップトリガー -->
                    <div class="relative group" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                        <!-- SVG アイコン -->
                        <img :src="tooltipIcn" alt="info" class="w-4 h-4">
                        <!-- ツールチップコンテンツ -->
                        <div v-if="showTooltip" class="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-lg p-2 -mt-1 ml-5" style="width: 200px; z-index: 50;">
                            絵文字、旧漢字、HTMLタグは使用できません。
                        </div>
                    </div>
                </div>
                <!-- テキストエリア -->
                <textarea v-model="articleBody" class="w-full h-52 p-2 border rounded"></textarea>
                <div v-if="!publishOption && validationAttempted" class="text-red-500">記事本文は必須です。また旧漢字やHTMLタグ、絵文字は使用できません。</div>
            </div>

            <div class="mb-4">

                <div class="flex justify-between items-center mb-4">
                    <div class="flex items-center mb-2">
                        <label class="block font-semibold text-base">YouTubeの動画内容</label>
                        <!-- ツールチップトリガー -->
                        <div class="relative group" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                            <!-- SVG アイコン -->
                            <img :src="tooltipIcn" alt="info" class="w-4 h-4">
                            <!-- ツールチップコンテンツ -->
                            <div v-if="showTooltip" class="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-lg p-2 -mt-1 ml-5" style="width: 200px; z-index: 50;">
                                絵文字、旧漢字、HTMLタグは使用できません。
                            </div>
                        </div>
                    </div>
                    <button @click="isInfoModalOpen = true" class="text-blue-500">YouTubeの動画内容とは</button>
                    <!-- YouTubeの動画内容説明モーダル -->
                    <div v-if="isInfoModalOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50" @click="closeModal">
                        <div class="bg-white p-5 rounded-lg max-w-md mx-auto" @click.stop>
                            <h3 class="text-xl font-bold mb-4">YouTubeの動画内容とは？</h3>
                            <p class="mb-2">記事本文の下に表示されるYouTubeの目次（チャプター）リンクです。読者が見たい情報が目に留まりやすくなるので、YouTubeへ遷移してもらいやすくなります。</p>
                            <p class="mb-4">※表示サンプル</p>

                            <!-- 実際のvideoSegmentsから説明要素を動的に表示 -->
                            <div class="border border-gray-200 rounded-lg">
                                <div v-for="(segment, index) in videoSegments" :key="index" class="flex items-center mt-2 mb-2 pl-2">
                                    <span class="text-timestampBlue bg-timestampLightBlue p-1 rounded mr-2">{{ segment.timestamp }}</span>
                                    <span class="text-gray-700">{{ segment.title }}</span>
                                </div>
                            </div>

                            <button @click="isInfoModalOpen = false" class="mt-4 bg-gray-500 text-white p-2 rounded">閉じる</button>
                        </div>
                    </div>
                </div>

                <!--timestamp部分-->
                <div>

                <draggable v-model="videoSegments" ghost-class="ghost" handle=".handle" chosen-class="chosen" animation="200">
    <template #item="{ element, index }">
      <div class="mb-2 relative flex items-center bg-gray-100 p-3 rounded">
        <button class="handle mr-2 flex items-center text-gray-500">
          <v-icon>mdi-drag-vertical</v-icon>
        </button>
        <span
          class="cursor-pointer text-blue-500 bg-blue-100 p-1 rounded mr-2 relative"
          @click="setVideoStart(element.timestamp)"
          @mouseover="showEditBlock(index)"
          @mouseleave="hideEditBlock(index)"
        >
          {{ element.timestamp }}
          <!-- 編集ブロック -->
          <div
            v-if="element.editing"
            class="absolute top-full left-0 mt-2 p-2 border rounded bg-white z-10 w-72"
            :class="{'z-20': element.isActive, 'z-10': !element.isActive}"
            @mouseenter="keepEditBlock(index)"
            @mouseleave="hideEditBlock(index)"
            @click.stop="bringToFront(index)"
            :ref="'editBlock' + index"
          >
            <div class="flex flex-col items-start mb-2 text-black">
                    <div class="flex justify-between items-center w-full mb-2">
              <span class="p-1 mb-2">
                時間を編集
              </span>
              <button @click="closeTimestampEdit(index)" class="text-black-500 mb-2">
          <v-icon>mdi-close</v-icon>
        </button>
                    </div>
              <div class="flex items-center w-full">
                <input
                  type="text"
                  v-model="element.newTimestamp"
                  class="p-2 border rounded w-full mb-2"
                  @input="validateTimestampOnInput(index)"
                >
                <button @click="toggleTimePicker(index)" class="ml-2 flex items-center mb-2">
                  <v-icon>mdi-clock-outline</v-icon>
                </button>
              </div>
              <button
                @click="updateTimestamp(index)"
                :disabled="!element.isValid"
                class="p-2 rounded w-full bg-emerald-600 hover:bg-emerald-700 text-white"
                :class="{'cursor-not-allowed opacity-50': !element.isValid}"
              >
                保存
              </button>
              <span
                v-if="!element.isValid"
                class="absolute bottom-full left-1/2 transform -translate-x-1/2  bg-black text-white text-xs rounded py-1 px-2 w-72 text-center"
              >
                半角数字と:のみ入力可能です。<br>
                形式は00:00:00に合わせてください。
              </span>
              <v-time-picker v-if="element.showTimePicker"
                             v-model="element.newTimestamp"
                             format="24hr"
                             @change="setSelectedTime(index, $event)"
                             @click:close="hideTimePicker(index)"
                             use-seconds
                             :style="{ width: '256px', padding: '10px 0px 0px 0px', margin:'0 auto' }" 
              ></v-time-picker>
              
            </div>
          </div>
        </span>
        <input
          type="text"
          v-model="element.title"
          class="p-2  rounded flex-grow mr-2 bg-white"
        >
        <button @click="deleteTimestamp(index)" class="ml-2 flex items-center text-red-500">
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
    </template>
  </draggable>

  <!-- 追加ブロック -->
<div class="flex items-center mb-4 mt-4 relative">
  <span
    class="cursor-pointer text-blue-500 bg-blue-100 p-1 rounded mr-2"
    @click="showNewTimestampEdit"
  >
    {{ displayTimestamp }}
  </span>
  <input
    type="text"
    v-model="newTitle"
    class="p-2 border rounded mr-2 flex-grow"
    placeholder="タイトルを記述してください。"
    @input="validateNewTitle"
  >
  <button
    @click="addTimestamp"
    :disabled="!isAddValid"
    
    :class="[
    'p-2 rounded text-white',
    isAddValid ? 'bg-emerald-600 hover:bg-emerald-700' : 'bg-gray-600 cursor-not-allowed'
  ]"
  >
    追加
  </button>
  <!-- 新しいタイムスタンプの編集ブロック -->
  <div
    v-if="newTimestampEditing"
    class="absolute top-full left-0 mt-2 p-2 border rounded bg-white z-10 w-72"
    @click.stop
  >
    <div class="flex flex-col items-start mb-2">
      <div class="flex justify-between items-center w-full mb-2">
        <span class="p-1 mb-2">
          時間を編集
        </span>
        <button @click="closeNewTimestampEdit" class="text-black-500 mb-2">
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
      <div class="flex items-center w-full">
        <input
          type="text"
          v-model="newTimestamp"
          @input="validateNewTimestampOnInput"
          class="p-2 border rounded w-full mb-2"
        >
        <button @click="toggleNewTimePicker" class="ml-2 flex items-center mb-2">
          <v-icon>mdi-clock-outline</v-icon>
        </button>
      </div>
      <button
        @click="confirmNewTimestamp"
        class="p-2 rounded w-full bg-emerald-600 hover:bg-emerald-700 text-white"
        :class="{'cursor-not-allowed opacity-50': !isNewTimestampValid}"
        :disabled="!isNewTimestampValid"
      >
        保存
      </button>
      <span
        v-if="!isNewTimestampValid"
        class="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 w-72 text-center"
      >
        半角数字と:のみ入力可能です。<br>
        形式は00:00:00に合わせてください。
      </span>
      <v-time-picker v-if="newTimePickerVisible"
                     v-model="newTimestamp"
                     format="24hr"
                     full-width
                     @change="handleTimePickerChange"
                     @click:close="hideNewTimePicker"
                     use-seconds
                     :style="{ width: '256px', padding: '10px 0px 0px 0px', margin:'0 auto', backgroundColor: 'white' }" 
      ></v-time-picker>
    </div>
  </div>
</div>
</div>
      
            </div>
        </div>

        <div class="lg:col-span-1">
            <iframe :src="youtubeEmbedUrl" title="YouTube video player" class="w-full aspect-video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>

            <div class="bg-gray-100 p-4 rounded text-sm">
                <p>YouTube動画URL<br><a :href="youtubeEmbedUrl" class="text-blue-500">{{youtubeEmbedUrl}}</a></p>
                <p>タイトル<br> {{ videotitle }}</p>
            </div>
        </div>

    </div>
    <!--操作ボタン-->

    <div class="flex flex-col sm:flex-row justify-between mt-8 w-full">
        <!-- キャンセルボタン -->
        <button @click="cancel" class="bg-gray-500 text-white py-2 px-4 rounded mb-2 sm:mb-0 sm:mr-2 max-h-fit">キャンセル</button>

        <!-- 右側のボタン群 -->
        <div class="flex justify-end sm:flex-row w-full sm:w-3/4 group">
            <button @click="openPreviewModal" class="bg-blue-500 text-white py-2 px-4 rounded sm:mb-0 sm:mr-2 flex-grow sm:flex-grow-0 max-h-fit">プレビューを見る</button>

            <button @click="submit" :disabled="!canSubmit" :class="{'bg-green-500 hover:bg-green-700': canSubmit, 'bg-gray-500 hover:bg-gray-700 cursor-not-allowed': !canSubmit}" class="text-white py-2 px-4 rounded w-full sm:w-auto max-h-fit ml-2">
                公開内容を確定する
            </button>
            <!-- ツールチップ -->
            <div v-if="!canSubmit" class="absolute hidden group-hover:flex flex-col items-center mt-2">
                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-700 shadow-lg rounded-md bottom-10">
                    必須項目を入力してください
                </span>
            </div>

        </div>
    </div>

    <!-- プレビューモーダル -->

    <div v-if="isPreviewModalOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center p-4" @click="closePreviewModal">
        <div class="bg-white p-5 rounded-lg max-w-lg max-h-[80vh] overflow-auto" @click.stop>
            <img :src="previewImage" alt="プレビュー画像" class="max-h-[calc(100vh-5rem)] max-w-full object-contain">
            <button @click="isPreviewModalOpen = false" class="mt-4 bg-gray-500 text-white p-2 rounded">閉じる</button>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import previewImage from '@/assets/previewImage.png';
import alertIcon from '@/assets/alert.svg';
import tooltipIcn from '@/assets/help.svg';
import draggable from 'vuedraggable';

//import { useVuetify } from 'vuetify/lib/framework';
export default {
    directives: {
        tooltip: {
            mounted(el, binding) {
                el.title = binding.value;
                el.classList.add('tooltip');
            }
        }
    },
    components: {
    draggable,
  },
    data() {
        return {
            articleId: null,
            publishOption: 'now',
            scheduledTime: '',
            youtubeEmbedUrl: '',
            videotitle: '',
            articleTitles: {},
            selectedTitle: '',
            customTitle: '',
            categories: ["政治", "国内", "経済", "IT", "海外", "エンタメ", "ゲーム", "アニメ", "マンガ＆ノベル", "サッカー", "野球", "格闘技", "競馬", "スポーツその他", "ファッション・ビューティー", "ライフスタイル", "グルメ", "恋愛", "健康", "仕事・教育"],
            selectedCategory: '',
            articleBody: '',
            videoSegments: [],
            validationAttempted: false, // バリデーションチェックが行われたかどうかを追跡するフラグ
            isInfoModalOpen: false,
            isPreviewModalOpen: false, // プレビューモーダルの表示状態
            previewImage, // プレビュー画像
            youtubeVideoId: '', // YouTube動画のID
            validationErrors: {}, // バリデーションエラーを保持するオブジェクト
            errorMessage: '', // エラーメッセージを保持するプロパティ
            tooltipIcn,
            alertIcon,
            showTooltip: false,
            isMouseOverEditBlock: false,
            currentEditingIndex: null,

            newTimestamp: '00:00:00', // 実際の値として使用
            displayTimestamp: '--:--:--', // 表示用のプロパティ
    newTitle: '',
    activeEditIndex: null,
    isAddValid: false,
    newTimestampEditing: false,
    newTimePickerVisible: false,
    isNewTimestampValid: false,
    isNewTitleValid: false, 
        };
    },
    mounted() {
        this.articleId = this.$route.params.id;
        this.fetchArticleSettings();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
    computed: {
        isScheduledTimeInvalid() {
            if (!this.scheduledTime) return false;
            const now = new Date();
            const scheduledDate = new Date(this.scheduledTime);
            return scheduledDate < now;
        },

        canSubmit() {
            // HTMLタグの存在チェック（script, img および 一般的なタグ）
            const containsHtmlTag_customtitle = /<[^>]+>/.test(this.customTitle);
            const containsHtmlTag_article_body = /<[^>]+>/.test(this.articleBody);
            // 旧漢字のチェック（例: "﨑" のような文字が含まれているか）
            const containsOldKanji_customtitle = /[亞惡壓圍爲醫稻隱衞驛鹽橫毆價畫勸觀閒邊邉龜豐鬥爭髙﨑圀龜豐鬥爭髙﨑圀]/.test(this.customTitle);
            const containsOldKanji_article_body = /[亞惡壓圍爲醫稻隱衞驛鹽橫毆價畫勸觀閒邊邉龜豐鬥爭髙﨑圀龜豐鬥爭髙﨑圀]/.test(this.articleBody);

            // 絵文字のチェック
            const containsEmoji_customtitle = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(this.customTitle);
            const containsEmoji_article_body = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(this.articleBody);

            // バリデーション条件
            const isTitleValid = this.selectedTitle && (this.selectedTitle !== 'custom' || (this.customTitle && !containsEmoji_customtitle)) && !containsHtmlTag_customtitle && !containsOldKanji_customtitle;
            const isCategoryValid = !!this.selectedCategory;
            const isBodyValid = !!this.articleBody && !containsEmoji_article_body && !containsHtmlTag_article_body && !containsOldKanji_article_body;

            // videoSegments内の各セグメントのタイトルに対するバリデーションチェック
            const areSegmentTitlesValid = this.videoSegments?.length === 1 ?
                (!/<[^>]+>/.test(this.videoSegments[0].title) && // HTMLタグがない
                    !/[亞惡壓圍爲醫稻隱衞驛鹽橫毆價畫勸觀閒邊邉龜豐鬥爭髙﨑圀]/.test(this.videoSegments[0].title) && // 旧漢字がない
                    !/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(this.videoSegments[0].title)) // 絵文字がない
                :
                this.videoSegments?.every(segment => {
                    return !/<[^>]+>/.test(segment.title) && // HTMLタグがない
                        !/[亞惡壓圍爲醫稻隱衞驛鹽橫毆價畫勸觀閒邊邉龜豐鬥爭髙﨑圀]/.test(segment.title) && // 旧漢字がない
                        !/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(segment.title); // 絵文字がない
                });

            // 予約投稿が選択されている場合、scheduledTimeもバリデーションチェック
            const isScheduledTimeValid = this.publishOption !== 'schedule' || (this.publishOption === 'schedule' && this.scheduledTime && !this.isScheduledTimeInvalid);

            return isTitleValid && isCategoryValid && isBodyValid && areSegmentTitlesValid && isScheduledTimeValid;
        },
    },
    methods: {
        fetchArticleSettings() {
            const accessToken = this.$store.state.accessToken;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
            axios.get(`${apiEndpoint}/articles/${this.articleId}/settings`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                .then(response => {
                    const data = response.data;
                    this.articleTitles = data.article_title_candidate;
                   // データの構造を確認して videoSegments を設定
                   if (data.video_chapter && data.video_chapter.segments) {
                this.videoSegments = data.video_chapter.segments.map(segment => ({
                    ...segment,
                    newTimestamp: segment.timestamp,
                    editing: false,
                    isValid: this.validateTimestamp(segment.timestamp),
                    showTimePicker: false,
                    isActive: false,
                }));
            } else if (data.video_chapter && data.video_chapter.segment) {
                this.videoSegments = data.video_chapter.segment.map(segment => ({
                    ...segment,
                    newTimestamp: segment.timestamp,
                    editing: false,
                    isValid: this.validateTimestamp(segment.timestamp),
                    showTimePicker: false,
                    isActive: false,
                }));
            }

                    this.articleBody = data.article_body;
                    this.youtubeEmbedUrl = this.convertToEmbedUrl(data.url_youtube);
                    this.videotitle = data.video_title;
                    this.youtubeVideoId = data.video_id_youtube;
                    // 以前ユーザーが選択した記事タイトルをセット（例）
                    this.selectedTitle = data.selected_title;
                    // 以前ユーザーが選択したカテゴリをセット（例）
                    this.selectedCategory = data.selected_category;
                    // 公開オプション（'now'または'schedule'）をセット（例）
                    this.publishOption = data.publish_option;
                    // 予約公開日時をセット（例）
                    this.scheduledTime = data.scheduled_time ? data.scheduled_time : '';

                    const now = new Date();
                    const publishedDate = new Date(data.date_published);

                    if (publishedDate > now) {
                        // 予約投稿の日時が現在より未来の場合
                        this.publishOption = 'schedule';

                        // publishedDate に 9 時間加算（9 * 60 * 60 * 1000 ミリ秒）
                        const jstDate = new Date(publishedDate.getTime() + 9 * 60 * 60 * 1000);
                        // JST 日時を ISO 8601 形式の文字列に変換し、日時ローカル入力フィールドの形式に合わせる
                        this.scheduledTime = jstDate.toISOString().slice(0, 16);
                    } else {
                        // それ以外の場合は「今すぐ公開」を選択
                        this.publishOption = 'now';
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorMessage = 'データの取得に失敗しました。画面の再読み込みを行ってください。';
                });
        },
        convertToEmbedUrl(url) {
            if (!url) return '';
            //const videoId = url.split('v=')[1];
            //const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed)\/|\S*?[?&]v=)|youtu.be\/)([a-zA-Z0-9_-]{11})/);
            const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed|shorts)\/|\S*?[?&]v=)|youtu.be\/)([a-zA-Z0-9_-]{11})/);

            // 正規表現で動画IDが取得できた場合
            if (videoIdMatch && videoIdMatch[1]) {
                return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
            }
            return '';
            //return `https://www.youtube.com/embed/${videoId}`;
        },
        cancel() {
            this.$router.push({
                name: 'ArticleManagement'
            });
        },
        preview() {
            // ここでプレビュー機能を実装します
        },
        submit() {
            this.validationAttempted = true; // バリデーションチェックを行ったことを示す
            if (!this.canSubmit) {
                // バリデーションエラーがある場合、ここで処理を中断
                this.errorMessage = 'フォームに未入力の必須項目があります。また、記事本文には旧漢字やHTMLタグを含めないでください。';
                return;
            }
            const accessToken = this.$store.state.accessToken;
            const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

            // timestampの編集情報から必要なキーだけを取得する処理
            const segmentsToSave = this.videoSegments.map(segment => ({
                title: segment.title,
                timestamp: segment.timestamp,
            }));
            let postData = {
                article_id: this.articleId,
                article_title: this.selectedTitle === 'custom' ? this.customTitle : this.selectedTitle,
                article_category: this.selectedCategory,
                publish_option: this.publishOption,
                article_body: this.articleBody,
                video_chapter: JSON.stringify({
                    segment: segmentsToSave // ここで必要なキーだけを使用
                }),
            };

            // 予約公開の場合、scheduledTimeをISO 8601形式で送信
            if (this.publishOption === 'schedule' && this.scheduledTime) {
                // Vue.jsのdatetime-local入力から得られる値は"YYYY-MM-DDTHH:MM"形式であるため、
                // これをISO 8601形式に変換（"YYYY-MM-DDTHH:MM:SSZ"形式への変換が必要な場合）
                postData['scheduled_time'] = `${this.scheduledTime}:00+09:00`;
            }

            axios.post(`${apiEndpoint}/articlesetting/`, postData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(response => {
                console.log(response);
                this.$router.push({
                    name: 'ArticleManagement'
                });
            }).catch(error => {
                console.error(error);
            });
        },
        closeModal() {
            this.isInfoModalOpen = false;
        },
        openPreviewModal() {
            this.isPreviewModalOpen = true;
        },
        closePreviewModal() {
            this.isPreviewModalOpen = false;
        },

        convertTimestampToSeconds(timestamp) {
            const parts = timestamp.split(':').reverse();
            let seconds = 0;
            for (let i = 0; i < parts.length; i++) {
                seconds += parseInt(parts[i], 10) * Math.pow(60, i);
            }
            return seconds;
        },

        
        validateNewTitle() {
    this.isNewTitleValid = this.newTitle.trim() !== '';
    this.updateAddButtonState();
  },
  validateNewTimestamp() {
    this.isNewTimestampValid = this.validateTimestamp(this.newTimestamp);
    this.updateAddButtonState();
  },
  updateAddButtonState() {
    this.isAddValid = this.isNewTitleValid && this.isNewTimestampValid && this.displayTimestamp !== '--:--:--';
  },
  validateNewTimestampOnInput(event) {
    this.newTimestamp = event.target.value;
    this.validateNewTimestamp();
  },

  handleTimePickerChange(value) {
    this.newTimestamp = value;
    this.displayTimestamp = value;
  },
handleTimestampInput() {
    this.isNewTimestampValid = this.validateTimestamp(this.newTimestamp);
  },
  addTimestamp() {
    if (this.isAddValid) {
      this.videoSegments.push({
        timestamp: this.newTimestamp,
        title: this.newTitle,
        newTimestamp: this.newTimestamp,
        editing: false,
        isValid: true,
        showTimePicker: false,
        isActive: false,
      });
      this.newTimestamp = '00:00:00';
      this.displayTimestamp = '--:--:--';
      this.newTitle = '';
      this.newTimestampEditing = false;
      this.isAddValid = false;
    }
  },
showNewTimestampEdit() {
    this.newTimestampEditing = true;
    this.isNewTitleValid = this.newTitle.trim() !== '';
    this.validateNewTimestamp();
  },

  closeTimestampEdit(index) {
    this.videoSegments[index].editing = false;
  },
  closeNewTimestampEdit() {
    this.newTimestampEditing = false;
  },


  confirmNewTimestamp() {
    if (this.isNewTimestampValid) {
      this.newTimestampEditing = false;
      this.displayTimestamp = this.newTimestamp;
      this.updateAddButtonState();
    }
  },
  toggleNewTimePicker() {
    this.newTimePickerVisible = !this.newTimePickerVisible;
  },
  hideNewTimePicker() {
    this.newTimePickerVisible = false;
  },

        setVideoStart(timestamp) {
            const seconds = this.convertTimestampToSeconds(timestamp);
            this.youtubeEmbedUrl = `https://www.youtube.com/embed/${this.youtubeVideoId}?start=${seconds}&autoplay=1&mute=1`;
        },
        deleteTimestamp(index) {
    this.videoSegments.splice(index, 1);
  },

    showEditBlock(index) {
    this.videoSegments[index].editing = true;
    this.videoSegments[index].isValid = this.validateTimestamp(this.videoSegments[index].newTimestamp);
},

    hideEditBlock(index) {
      setTimeout(() => {
        if (!this.isMouseOverEditBlock) {
          this.videoSegments[index].editing = false;
          this.videoSegments[index].showTimePicker = false;
        }
      }, 200); // 短い遅延を設けることでマウス移動を検出
    },
    keepEditBlock(index) {
      this.isMouseOverEditBlock = true;
      this.videoSegments[index].editing = true;
    },

    updateTimestamp(index) {
    // バリデーションが成功した場合にのみタイムスタンプを更新
    if (this.videoSegments[index].isValid) {
        this.videoSegments[index].timestamp = this.videoSegments[index].newTimestamp;
        this.videoSegments[index].editing = false;
    } else {
        this.errorMessage = 'タイムスタンプに無効な文字が含まれています。';
    }
},
setSelectedTime() {
      if (this.selectedSegmentIndex !== null) {
        this.videoSegments[this.selectedSegmentIndex].newTimestamp = this.selectedTime;
        this.dialog = false;
      }
    },
validateTimestampOnInput(index) {
        const newTimestamp = this.videoSegments[index].newTimestamp;
        this.videoSegments[index].isValid = this.validateTimestamp(newTimestamp);
    },
    handleClickOutside(event) {
      let isClickInside = false;
      for (let i = 0; i < this.videoSegments.length; i++) {
        const editBlockArray = this.$refs['editBlock' + i];
        if (editBlockArray) {
          if (Array.isArray(editBlockArray)) {
            for (const editBlock of editBlockArray) {
              if (editBlock && editBlock.contains(event.target)) {
                isClickInside = true;
                break;
              }
            }
          } else if (editBlockArray.contains(event.target)) {
            isClickInside = true;
            break;
          }
        }
      }
      if (!isClickInside) {
        this.closeEditBlock();
      }
    },

  closeEditBlock() {
    this.videoSegments.forEach(segment => {
      segment.editing = false;
    });
  },

    validateTimestamp(timestamp) {
    // 正しい形式のチェック（半角数字のみ許可）
    const regex = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    // 全角文字が含まれていないかチェック（全角数字や全角コロンを含まない）
    const isFullWidth = /[０-９：]/.test(timestamp);
    // バリデーション条件を満たしているかを確認
    return regex.test(timestamp) && !isFullWidth;
},
bringToFront(index) {
      this.videoSegments.forEach((element, i) => {
        element.isActive = (i === index);
      });
    },
toggleTimePicker(index) {
      this.videoSegments[index].showTimePicker = !this.videoSegments[index].showTimePicker;
    },
showTimePicker(index) {
    this.videoSegments[index].showTimePicker = true;
},
hideTimePicker(index) {
    this.videoSegments[index].showTimePicker = false;
},
setNewSelectedTime(time) {
    this.updateTimestamp(time);
  },
    // エラーメッセージをクリアする関数
    clearErrorMessage() {
        this.errorMessage = '';
    },

    }
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
}


</style>
